<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showcaution"
        max-width="800px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span>{{ formTitle }}</span>
          </v-card-title>
          <v-card-subtitle>
            <span> Montant Restant : </span>

            <span class="green--text">
              {{
                numberWithSpace(
                  caution.reste ? caution.reste.toFixed(2) : "0.00"
                ) +
                " " +
                caution.devise
              }}
            </span>
          </v-card-subtitle>
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    dense
                    :items="operation"
                    v-model="editedItem.type_doc"
                    item-text="libelle"
                    item-value="id"
                    label="Opération"
                    :rules="[(v) => !!v || 'Opération obligatoire']"
                    :readonly="readonly"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6"> </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    autocomplete="off"
                    v-model="editedItem.ref_doc"
                    label="Réference"
                    :rules="[(v) => !!v || 'Réference obligatoire']"
                    @focus="$event.target.select()"
                    :readonly="readonly"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <datepicker
                    label=" Date Opération"
                    v-model="editedItem.date_opr"
                    :edit="!readonly"
                    :rules="[
                      (v) => !!v || 'Date Opération obligatoire',
                      (v) =>
                        !v ||
                        editedItem.date_opr > caution.date_caution ||
                        'Date Incorrecte',
                    ]"
                    :date_max="
                      editedItem.type_doc == '2' ? null : $store.state.today
                    "
                    :key="kdd"
                  ></datepicker>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  v-if="editedItem.type_doc != '2'"
                >
                  <v-text-field
                    autocomplete="off"
                    dense
                    ref="mnt"
                    type="number"
                    :label="'Montant'"
                    v-model.number="editedItem.montant"
                    :rules="[
                      (v) => !!v || 'Montant obligatoire',
                      (v) =>
                        !v ||
                        v <= caution.reste ||
                        'Maximum : ' + caution.reste,
                    ]"
                    :readonly="readonly"
                    hide-spin-buttons
                  />
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    autocomplete="off"
                    dense
                    v-model="editedItem.comment"
                    label="Commentaire"
                    :readonly="readonly"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>

              <v-spacer></v-spacer>

              <v-btn color="blue darken-1" text @click="save">
                Enregistrer
              </v-btn>
            </v-container>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <template>
      <confirmdialog ref="confirm" />
    </template>
  </div>
</template>

<script>
import CREATE_CAUTION_DETAIL from "../graphql/Caution/CREATE_CAUTION_DETAIL.gql";
import UPDATE_CAUTION_DETAIL from "../graphql/Caution/UPDATE_CAUTION_DETAIL.gql";

export default {
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    datepicker: () => import("./DatePicker.vue"),
  },
  name: "cautiondetailform",
  props: {
    items: Array,
    caution: Object,
    item: Object,
    showForm: Boolean,
    readonly: Boolean,
  },
  data: () => ({
    progress: false,
    valid: true,
    operation: [
      { id: "1", libelle: "Main Levée" },
      { id: "2", libelle: "Proroger" },
      { id: "3", libelle: "Saisir" },
    ],
    kdd: 100,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",

    editedItem: {},
    old_montant: 0,
    type_doc: "1",
  }),

  computed: {
    showcaution() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Ligne  "
          : "Ligne N° ".concat(parseInt(this.item.index) + 1);
      else return "";
    },
  },
  watch: {},

  created() {},
  mounted() {
    this.$refs.form.resetValidation();
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);
      this.kdd++;
    }
  },

  methods: {
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
            this.$emit("change");
          }
          r = data.data;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    toggle(isSelected, select) {
      select(!isSelected);
    },

    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },

    close() {
      this.$emit("close", this.editedItem);
    },

    async save() {
      if (this.$refs.form.validate()) {
        this.editedItem.operation = this.operation.find(
          (elm) => elm.id == this.editedItem.type_doc
        ).libelle;
        this.progress = true;
        if (this.editedItem.id > 0) {
          let v = {
            cautiondetail: {
              id: this.editedItem.id,
              ref_doc: this.editedItem.ref_doc,
              type_doc: this.editedItem.type_doc,
              date_opr: this.editedItem.date_opr,
              montant: this.editedItem.montant,
              comment: this.editedItem.comment,
              write_uid: this.$store.state.me.id,
            },
          };
          await this.maj(UPDATE_CAUTION_DETAIL, v, true);
          this.items.splice(this.item.index, 1, this.editedItem);
        } else {
          let v = {
            cautiondetail: {
              caution_id: this.caution.id,
              ref_doc: this.editedItem.ref_doc,
              type_doc: this.editedItem.type_doc,
              date_opr: this.editedItem.date_opr,
              montant: this.editedItem.montant,
              comment: this.editedItem.comment,

              create_uid: parseInt(this.$store.state.me.id),
              write_uid: parseInt(this.$store.state.me.id),
            },
          };
          let r = await this.maj(CREATE_CAUTION_DETAIL, v, true);
          if (r) this.editedItem.id = r.createCautionDetail.id;
          this.items.push(this.editedItem);
        }
        this.close();
      }
    },
  },
};
</script>
